import { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Header from 'components/Header';
import Loader from 'components/Loader';

const Landing = lazy(() => import('pages/Landing'));
const Home = lazy(() => import('pages/Home'));
const Blog = lazy(() => import('pages/Blog'));
const LegalesMentions = lazy(() => import('pages/LegalesMentions'));
const AddTestimonial = lazy(() => import('pages/AddTestimonial'));

import 'styles/index.scss';

const App = () => {
  const { pathname } = useLocation();

  return (
    <div className="App">
      {pathname !== '/' && <Header />}

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/accueil" element={<Home />} />
          {/* <Route path="/ajouter-mon-témoignage" element={<AddTestimonial />} /> */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/mentions-légales" element={<LegalesMentions />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
